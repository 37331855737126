import getWeb3 from './web3';
import TwoKeyRegistry from '../abi/TwoKeyRegistry';
import { promisify } from '../../_core/promisify';

const plasmaWeb3 = getWeb3(true);
const contract = plasmaWeb3.eth
  .contract(TwoKeyRegistry)
  .at(window.CONFIG.contracts.TwoKeyPlasmaRegistry);

const validateSignature = async signature => promisify(contract.recover, [signature]);

export default validateSignature;
