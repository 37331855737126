import sigUtil from 'eth-sig-util';
import * as eth_util from 'ethereumjs-util';

const Sign = Object.freeze({
  add0x(x) {
    if (!x) {
      return '0x';
    }
    if (x.startsWith('0x')) {
      return x;
    }
    return `0x${x}`;
  },
  remove0x(x) {
    if (!x) {
      return null;
    }
    if (x.startsWith('0x')) {
      if (x.length === 2) {
        return null;
      }
      return x.slice(2);
    }
    return x;
  },
  fixCut(referrerCut) {
    if (!referrerCut) {
      return 0;
    }
    let cut = referrerCut;
    if (typeof cut !== 'number') {
      cut = parseInt(cut, 10);
    }
    if (cut > 0 && cut <= 100) {
      cut += 1;
    } else if (cut < 0 && cut >= -100) {
      cut = 255 + cut;
    } else if (cut !== 255) {
      cut = 0;
    }
    return cut;
  },
  privateToPublic(private_key) {
    // convert a private_key buffer to a public address string
    return eth_util.publicToAddress(eth_util.privateToPublic(private_key)).toString('hex');
  },
  ecsign(message, private_key) {
    const msg = Buffer.from(this.remove0x(message), 'hex');
    const msgHash = eth_util.keccak(msg);
    const sig = eth_util.ecsign(msgHash, private_key);
    if (!(sig.v === 27 || sig.v === 28)) {
      throw new Error('unknown sig.v');
    }
    const signature = Buffer.concat([sig.r, sig.s, Buffer.from([sig.v])]).toString('hex');
    return `0x${signature}`;
  },
  sign_referrerWithPlasma(plasma_web3, plasma_address, action) {
    const msgParams = [
      {
        type: 'bytes', // Any valid solidity type
        name: 'binding referrer to plasma', // Any string label you want
        value: this.add0x(Buffer.from(action, 'ascii').toString('hex')), // The value to sign
      },
    ];
    // we never use metamask on plasma
    return this.sign_message(plasma_web3, msgParams, plasma_address, { plasma: true });
  },
  sign_message(web3, msgParams, from, opts = {}) {
    return new Promise((resolve, reject) => {
      const { isMetaMask = false, isWalletConnect } = web3.currentProvider;

      function sign_message_callback(err, result) {
        if (err) {
          reject(err);
        } else if (!result) {
          reject();
        } else {
          let sig = typeof result !== 'string' ? result.result : result;

          if (opts.plasma || (!isMetaMask && !isWalletConnect)) {
          // TODO: Check that V is 27 or 28
            // TODO: Find out what to do if v=0 or v=1
            // TODO: Extract s frmo the signature and check it like we did in ecsign method
            const n = sig.length;
            let v = sig.slice(n - 2);
            v = parseInt(v, 16) + 32;
            v = Buffer.from([v]).toString('hex');
            sig = sig.slice(0, n - 2) + v;
          }

          resolve(sig);
        }
      }

      if (!opts.plasma && isMetaMask) {
        if (typeof msgParams !== 'object') {
          throw new Error('bad msgParams');
        }
        // metamask uses  web3.eth.sign to sign transaction and not for arbitrary messages
        // instead use https://medium.com/metamask/scaling-web3-with-signtypeddata-91d6efc8b290
        web3.currentProvider.sendAsync({
          method: 'eth_signTypedData',
          params: [msgParams, from],
          from,
        }, sign_message_callback);
      } else {
        let hash;
        if (typeof msgParams === 'object') {
          hash = sigUtil.typedSignatureHash(msgParams);
        } else {
          if (!msgParams.startsWith('0x')) {
            throw new Error('msgParams not 0x');
          }
          hash = web3.sha3(msgParams);
        }
        if (isWalletConnect) {
          // eslint-disable-next-line no-underscore-dangle
          web3.currentProvider._providers[0].processMessage({ from, data: hash }, sign_message_callback);
        } else if (web3.eth.getSign) {
          web3.eth.getSign(from, hash, sign_message_callback);
        } else {
          web3.eth.sign(from, hash, sign_message_callback);
        }
      }
    });
  },
  free_take(my_address, f_address, f_secret, pMessage) {
  // using information in the signed link (f_address,f_secret,p_message)
  // return a new message that can be passed to the transferSig method of the contract
  // to move ARCs arround in the current. For example:
  //   campaign_contract.transferSig(free_take (my_address,f_address,f_secret,p_message))
  //
  // my_address - I'm a new influencer or a converter
  // f_address - previous influencer
  // f_secret - the secret of the parent (contractor or previous influencer) is passed in the 2key link
  // p_message - the message built by previous influencers
    const old_private_key = Buffer.from(this.remove0x(f_secret), 'hex');
    if (!eth_util.isValidPrivate(old_private_key)) {
      throw new Error('old private key not valid');
    }

    let m;
    let version;
    let p_message = pMessage;
    // let prefix = "00"  // not reall important because it only used when receiving a free link directly from the contractor

    if (p_message) { // the message built by previous influencers
      p_message = this.remove0x(p_message);
      version = p_message.slice(0, 2);
    } else {
      version = '00';
    }

    if (p_message) {
      m = p_message;
      if (version === '00') {
        m += this.remove0x(f_address);
      }
      m += this.privateToPublic(old_private_key);
    } else {
      m = version + this.remove0x(f_address);
    }

    m = `0x${m}${this.remove0x(this.ecsign(my_address, old_private_key))}`;
    return m;
  },
  free_join(my_address, public_address, f_address, f_secret, p_message, rCut, cutSign) {
  // let cut = fcut;
  // Input:
  //   my_address - I'm an influencer that wants to generate my own link
  //   public_address - the public address of my private key
  // return - my new message

    // the message we want to sign is my address (I'm the influencer or converter)
    // and the public key of the private key which I will put in the link
    // and we will sign all of this with the private key from the previous step,
    // this will prove that I (my address) knew what the previous private key was
    // and it will link the new private/public key to the previous keys to form a path
    const msg0 = Buffer.from(public_address, 'hex');
    const msg1 = Buffer.from(this.remove0x(my_address), 'hex'); // skip 0x
    let msg = Buffer.concat([msg0, msg1]); // compact msg (as is done in sha3 inside solidity)
    // if not using version prefix to the message:
    let cut = rCut;
    if (cut == null || cut === 0) {
      cut = 255; // equal partition
    }
    cut = Buffer.from([cut]);
    msg = Buffer.concat([cut, msg]); // compact msg (as is done in sha3 inside solidity)
    const msgHash = eth_util.keccak(msg);
    const old_private_key = Buffer.from(this.remove0x(f_secret), 'hex');
    let sig = eth_util.ecsign(msgHash, old_private_key);

    // check the signature
    // this is what the contract will do
    let recovered_address = eth_util.ecrecover(msgHash, sig.v, sig.r, sig.s);
    // @ts-ignore: Missing declaration of publicToAddress in ethereumjs-util
    recovered_address = eth_util.publicToAddress(recovered_address).toString('hex');
    const old_public_address = this.privateToPublic(old_private_key);
    if (recovered_address !== old_public_address) {
      throw new Error('sig failed');
    }

    sig = Buffer.concat([sig.r, sig.s, Buffer.from([sig.v])]);
    let m = Buffer.concat([sig, cut]);
    m = m.toString('hex');

    const version = cutSign ? '01' : '00';
    let previousMessage = p_message;
    if (previousMessage) {
      if (version === '00') {
        previousMessage += this.remove0x(f_address);
      }
      previousMessage += old_public_address;
    // m = previousMessage + f_address.slice(2) + old_public_address + m;
    } else {
      previousMessage = version + this.remove0x(f_address);
    // this happens when receiving a free link directly from the contractor
    // m = f_address.slice(2) + m;
    }
    if (!previousMessage.startsWith(version)) {
      throw new Error('sig version error');
    }
    m = previousMessage + m;
    if (cutSign) {
      if (version !== '01') {
        throw new Error('sig 01 version error');
      }
      m += this.remove0x(cutSign);
    }
    return m;
  },
});

export default Sign;
