import * as eth_wallet from 'ethereumjs-wallet';
import Web3 from 'web3';
import TwoKeyStorage from '../../_core/2KeyStorage';
import { fetchRequest } from '../../_core/http/helpers';
import { createProviderEngine } from '../../_core/web3ProvidersPrototypes';
import WalletSubprovider from '../../_core/walletProvider';
import Sign from '../helpers/sign';
import addressChecker from '../helpers/addressChecker';
import validateSignature from '../helpers/validatePlasmaSignature';

export const web3PKService = Object.freeze({
  validatePK(pk) {
    try {
      eth_wallet.fromPrivateKey(Buffer.from(pk, 'hex'));
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  },
  getWalletFromPK(pk) {
    const wallet = eth_wallet.fromPrivateKey(Buffer.from(pk, 'hex'));
    return { wallet, address: `0x${wallet.getAddress().toString('hex')}` };
  },
  getPlasmaWeb3(plasmaPK) {
    const { wallet: plasmaWallet, address: plasmaAddress } = this.getWalletFromPK(plasmaPK);
    const plasmaEngine = createProviderEngine();
    plasmaEngine.addProvider(new WalletSubprovider(plasmaWallet, {}));
    plasmaEngine.addRpcProvidersToEngine(true);
    plasmaEngine.start();
    const plasmaWeb3 = new Web3(plasmaEngine);
    return { plasmaWeb3, plasmaAddress, plasmaPK };
  },

  async generateAndValidatePK(plasma = true, pk, unique, checkBindings) {
    let isKeyValid = false;
    let isTimeoutReached = false;
    let privateKey;
    let signature;
    let timeout = setTimeout(() => {
      isTimeoutReached = true;
    }, 60000);

    while (!(isKeyValid || isTimeoutReached)) {
      let wallet;
      if (!pk) {
        wallet = eth_wallet.generate();
        privateKey = wallet.getPrivateKey().toString('hex');
      } else {
        privateKey = pk;
        wallet = eth_wallet.fromPrivateKey(Buffer.from(pk, 'hex'));
        isTimeoutReached = true;
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
      }
      const address = `0x${wallet.getAddress().toString('hex')}`;
      /* eslint-disable no-await-in-loop */
      try {
        const web3AddressUsed = unique && (await fetchRequest(
          'web3address/handle',
          { params: { web3_address: address } }
        ).then(res => res.json())).web3_address_in_use;
        if (!web3AddressUsed) {
          const { plasmaAddress, plasmaWeb3 } = this.getPlasmaWeb3(privateKey || pk);

          signature = plasma
            ? await Sign.sign_referrerWithPlasma(plasmaWeb3, plasmaAddress, 'GET_REFERRER_REWARDS')
            : '0x0';
          const decryptedAddress = plasma ? await validateSignature(signature) : address;
          const canBindPlasma2Ethereum = checkBindings
            ? (await addressChecker.checkIfArgumentsForRegistrationAreUnique(
              '0x0000000000000000000000000000000000000000',
              plasmaAddress
            ))
            : true;
          if (address === decryptedAddress && canBindPlasma2Ethereum) {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
            }
            isKeyValid = true;
          }
        }
      } catch (e) {
        console.warn(e);
      }
      /* eslint-enable no-await-in-loop */
    }

    return {
      privateKey, isKeyValid, isTimeoutReached, signature,
    };
  },

  async getClientPlasmaKey() {
    let plasmaKey = TwoKeyStorage.getItem('plasma');
    if (!plasmaKey || plasmaKey.includes('{')) {
      const key = await this.generateAndValidatePK(true, null, true, true);
      console.log('PLASMA_KEY', key);
      plasmaKey = key.privateKey;
      if (key.isKeyValid) {
        TwoKeyStorage.setItem('plasma', plasmaKey);
      } else if (key.isTimeoutReached) {
        throw new Error('timeout!');
      }
    }
    return plasmaKey;
  },
});

export default web3PKService;
