export default [
  {
    constant: true,
    inputs: [{ name: '_plasma', type: 'address' }],
    name: 'plasma2ethereum',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: '_ethereum', type: 'address' }],
    name: 'ethereum2plasma',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
];
