import { promisify } from '../../_core/promisify';
import TwoKeyRegistry from '../abi/TwoKeyRegistry';
import TwoKeyPlasmaRegistry from '../abi/TwoKeyPlasmaRegistry';
import getWeb3 from './web3';

const addressChecker = Object.freeze({
  web3: getWeb3(),
  plasmaWeb3: getWeb3(true),
  async areRegistrationAddressesUniqueOnPublicRegistry(ethereumAddress, plasmaAddress) {
    const contract = this.web3.eth
      .contract(TwoKeyRegistry)
      .at(window.CONFIG.contracts.TwoKeyRegistry);
    let [addressAssignedToTheEthereum, addressAssignedToPlasma] = await Promise.all([
      promisify(contract.getEthereumToPlasma, [ethereumAddress]),
      promisify(contract.getPlasmaToEthereum, [plasmaAddress]),
    ]);
    if (addressAssignedToTheEthereum.toLowerCase() === ethereumAddress.toLowerCase()) {
      addressAssignedToTheEthereum = 0;
    }
    if (addressAssignedToPlasma.toLowerCase() === plasmaAddress.toLowerCase()) {
      addressAssignedToPlasma = 0;
    }
    return (addressAssignedToTheEthereum === plasmaAddress || parseInt(addressAssignedToTheEthereum, 16) === 0)
      && (addressAssignedToPlasma === ethereumAddress || parseInt(addressAssignedToPlasma, 16) === 0);
  },
  async areRegistrationAddressesUniqueOnPlasmaRegistry(ethereumAddress, plasmaAddress) {
    const contract = this.plasmaWeb3.eth
      .contract(TwoKeyPlasmaRegistry)
      .at(window.CONFIG.contracts.TwoKeyPlasmaRegistry);
    const [addressAssignedToTheEthereum, addressAssignedToPlasma] = await Promise.all([
      promisify(contract.ethereum2plasma, [ethereumAddress]),
      promisify(contract.plasma2ethereum, [plasmaAddress]),
    ]);
    return (addressAssignedToTheEthereum === plasmaAddress || parseInt(addressAssignedToTheEthereum, 16) === 0)
      && (addressAssignedToPlasma === ethereumAddress || parseInt(addressAssignedToPlasma, 16) === 0);
  },
  async checkIfArgumentsForRegistrationAreUnique(ethereumAddress, plasmaAddress) {
    const [publicCheck, privateCheck] = await Promise.all([
      this.areRegistrationAddressesUniqueOnPublicRegistry(ethereumAddress, plasmaAddress),
      this.areRegistrationAddressesUniqueOnPlasmaRegistry(ethereumAddress, plasmaAddress),
    ]);
    return publicCheck && privateCheck;
  },
});


export default addressChecker;
