export default [
  {
    constant: true,
    inputs: [{ name: 'ethereumAddress', type: 'address' }],
    name: 'getEthereumToPlasma',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: 'plasmaAddress', type: 'address' }],
    name: 'getPlasmaToEthereum',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: 'signature', type: 'bytes' }],
    name: 'recover',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
];
